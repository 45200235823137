import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { jwtDecode } from 'jwt-decode';

import { environment } from 'src/environments/environment';
import { Account } from './models/account';

@Injectable({
  providedIn: 'root',
})

export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string) {
    return this.http.post<any>(`${environment.apiUrl}/Authentication/login`, { email, password })
      .pipe(map(response => {
        // assume response.token contains the JWT token
        const token = response.token;
        const decoded = jwtDecode(token) as any; // Use jwt-decode to parse the JWT

        // Save the entire token to localStorage or session as per your requirement
        localStorage.setItem('currentUser', JSON.stringify({ token }));
        // Additionally, save the extracted details
        console.log('Decoded:', decoded);
        localStorage.setItem('userDetails', JSON.stringify({
          name: decoded.name,
          email: decoded.email,
          country: decoded.country,
          phoneNumber: decoded.PhoneNumber,
          role: decoded.role,
          userName: decoded.UserName
        }));

        this.currentUserSubject.next({ token, ...decoded });
        return { token, ...decoded };
      }));
  }

  signup(account: Account): Observable<any> {
    return this.http.post(`${environment.apiUrl}/Authentication/register`, account)
      .pipe(
        mergeMap(() => this.login(account.email, account.password)),
        map(data => ({ success: true, error: null, data })),
        catchError(error => {
          let errorMessages = [];
          if (error.error.errors) {
            for (let key in error.error.errors) {
              errorMessages.push(...error.error.errors[key]);
            }
          } else {
            errorMessages.push(error.error.message || error.statusText);
          }
          return of({ success: false, error: errorMessages, data: null });
        })
      );
  }



  logout() {
    // Remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('userDetails');
    this.currentUserSubject.next(null);
    window.location.reload();
    window.location.href = '/auth/login';
  }
}
export class AuthService {
  private apiUrl = 'https://hubcharge-api.otimiza.ai';
  private readonly authKey = 'isAuthenticated';

  constructor(private http: HttpClient) { }

  login(email: string, password: string): Observable<string> {
    var model = { email: email, password: password };
    return this.http.post<string>(`${this.apiUrl}/Authentication/login`, model);
  }

  logout() {
    localStorage.removeItem(this.authKey);
  }

  get isLoggedIn(): boolean {
    return localStorage.getItem(this.authKey) === 'true';
  }
}
