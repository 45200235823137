import { Component, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-language-selector',
    template: `
        <p-dropdown [options]="languages" [(ngModel)]="selectedLanguage" (onChange)="changeLanguage($event)" optionLabel="name" optionValue="code">
            <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center gap-2">
                    <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + selectedFlag" style="width: 18px" />
                    <div>{{getSelectedLanguageName()}}</div>
                </div>
            </ng-template>
            <ng-template let-language pTemplate="item">
                <div class="flex align-items-center gap-2">
                    <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + language.flag" style="width: 18px" />                    
                    <div>{{language.name}}</div>
                </div>
            </ng-template>
        </p-dropdown>
    `
})
export class LanguageSelectorComponent {
    @Output() onChange = new EventEmitter<any>();

    languages = [
        { name: 'English', code: 'en', flag: 'us' },
        { name: 'Português', code: 'pt-BR', flag: 'br' }
    ];

    selectedLanguage: string;
    selectedFlag: string;

    constructor(private translate: TranslateService) {
        this.selectedLanguage = this.translate.currentLang == 'pt-BR' ? 'pt-BR': 'en';
        this.selectedFlag = this.translate.currentLang == 'pt-BR' ? 'br' : 'us';
    }

    changeLanguage(event: any) {
        this.translate.use(event.value);
        this.selectedLanguage = event.value;
        this.selectedFlag = event.value === 'pt-BR' ? 'br' : 'us';
        this.onChange.emit(event);
    }

    getSelectedLanguageName(): string {
        return this.selectedLanguage === 'pt-BR' ? 'Português' : 'English';
    }
}