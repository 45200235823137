import { Component, ElementRef, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from "./service/app.layout.service";
import { AuthenticationService } from '../auth.service';
import { LanguageSelectorComponent } from './language-selector.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {

    items!: MenuItem[];

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

    constructor(
        public layoutService: LayoutService, 
        private authenticationService: AuthenticationService,
        private translateService: TranslateService
    ) { }
   
    logout(){
        this.authenticationService.logout();
        window.location.reload();
    }

    get themeMode(): boolean {
        return this.layoutService.config().ripple;
    }
    set themeMode(_val: boolean) {
        var themeName = 'lara-light-purple'
        if (_val) 
            themeName = 'lara-dark-purple'

        console.log(themeName)
        this.layoutService.config.update((config) => ({
            ...config,
            theme: themeName,
        }));
    }

    // Add this method to handle language changes
    onLanguageChange(event: any) {
        this.translateService.use(event.value);
    }
}
