import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];

    constructor(
        public layoutService: LayoutService,
        private translate: TranslateService
    ) { }

    ngOnInit() {
        this.updateMenu();
        this.translate.onLangChange.subscribe(() => {
            this.updateMenu();
        });
    }

    updateMenu() {
        this.model = [
            {
                label: this.translate.instant('HOME'),
                items: [
                    { label: this.translate.instant('HUB'), icon: 'pi pi-fw pi-home', routerLink: ['/'] }
                ]
            },
            {
                label: this.translate.instant('ACCOUNT'),
                items: [
                    { label: this.translate.instant('PROFILE'), icon: 'pi pi-fw pi-user', routerLink: ['account/profile'] },
                    { label: this.translate.instant('BILLING_HISTORY'), icon: 'pi pi-fw pi-bars', routerLink: ['/account/billing/history'] },
                    { label: this.translate.instant('PAYMENT_METHODS'), icon: 'pi pi-fw pi-id-card', routerLink: ['/account/billing/payment-methods'] },
                    { label: this.translate.instant('REFERRALS'), icon: 'pi pi-fw pi-users', routerLink: ['account/referrals'] },
                ]
            }
        ];
    }
}
