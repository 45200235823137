import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PhoneCard } from 'src/app/models/phoneCard';
import { environment } from 'src/environments/environment';
import { PhoneOperatorData } from 'src/app/models/phoneOperator';

@Injectable({
  providedIn: 'root'
})
export class PhoneCardService {

  constructor(private http: HttpClient) { }

  createPhoneCard(phoneCard: PhoneCard): Observable<PhoneCard> {
    return this.http.post<PhoneCard>(`${environment.apiUrl}/phonecard`, phoneCard);
  }

  getPhoneCards(): Observable<PhoneCard[]> {
    console.log('PhoneCardService.getPhoneCards');
    return this.http.get<PhoneCard[]>(`${environment.apiUrl}/phonecard/all`);
  }  

  getPhoneCard(id: string): Observable<PhoneCard> {
    return this.http.get<PhoneCard>(`${environment.apiUrl}/phonecard/${id}`);
  }

  updatePhoneCard(id: string, phoneCard: PhoneCard): Observable<PhoneCard> {
    return this.http.put<PhoneCard>(`${environment.apiUrl}/phonecard/${id}`, phoneCard);
  }

  getOperatorData(phoneNumber: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/phonecard/operator/${phoneNumber}`);
  }

  deletePhoneCard(id: string): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/phonecard/${id}`);
  }
}