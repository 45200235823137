import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    constructor(
        private primengConfig: PrimeNGConfig,
        private translate: TranslateService,
        private meta: Meta,
        private titleService: Title
    ) {}

    ngOnInit() {
        this.primengConfig.ripple = true;
        this.translate.setDefaultLang('pt-BR');
        this.translate.use('pt-BR'); // or whatever default language you want to use
        this.updateMetaTags();

        // Listen for language changes
        this.translate.onLangChange.subscribe(() => {
            this.updateMetaTags();
        });
    }

    updateMetaTags() {
        this.translate.get([
            'APP_TITLE',
            'META_DESCRIPTION',
            'META_KEYWORDS',
            'OG_TITLE',
            'OG_DESCRIPTION',
            'TWITTER_TITLE',
            'TWITTER_DESCRIPTION'
        ]).subscribe(translations => {
            this.titleService.setTitle(translations['APP_TITLE']);
            this.meta.updateTag({ name: 'description', content: translations['META_DESCRIPTION'] });
            this.meta.updateTag({ name: 'keywords', content: translations['META_KEYWORDS'] });
            this.meta.updateTag({ property: 'og:title', content: translations['OG_TITLE'] });
            this.meta.updateTag({ property: 'og:description', content: translations['OG_DESCRIPTION'] });
            this.meta.updateTag({ name: 'twitter:title', content: translations['TWITTER_TITLE'] });
            this.meta.updateTag({ name: 'twitter:description', content: translations['TWITTER_DESCRIPTION'] });
        });
    }
}
