<div class="layout-topbar">
    <a class="layout-topbar-logo" routerLink="">
        <img style="height: 3.5rem;" src="{{layoutService.getLogo()}}" alt="logo">
    </a>

    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>

    <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">        
        <app-language-selector (onChange)="onLanguageChange($event)"></app-language-selector>
        <a href="/account/profile" class="p-link layout-topbar-button">
            <i class="pi pi-user"></i>
            <span>{{ 'PROFILE' | translate }}</span>
        </a>
        <a href="#" (click)="logout()" class="p-link layout-topbar-button">
            <i class="pi pi-sign-out"></i>
            <span>{{ 'LOGOUT' | translate }}</span>
        </a>
    </div>
</div>