import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaymentMethodDto, PaymentMethodResponseDto } from 'src/app/models/paymentMethod';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodService {

  constructor(private http: HttpClient) { }

  createPaymentMethod(paymentMethod: PaymentMethodDto): Observable<PaymentMethodResponseDto> {
    return this.http.post<PaymentMethodResponseDto>(`${environment.apiUrl}/paymentMethods`, paymentMethod);
  }

  getPaymentMethods(): Observable<PaymentMethodResponseDto[]> {
    return this.http.get<PaymentMethodResponseDto[]>(`${environment.apiUrl}/paymentMethods/all`);
  }

  getPaymentMethod(id: string): Observable<PaymentMethodResponseDto[]> {
    return this.http.get<PaymentMethodResponseDto[]>(`${environment.apiUrl}/paymentMethods/${id}`);
  }

  deletePaymentMethod(id: string): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/paymentMethods/${id}`);
  }

  setDefaultPaymentMethod(id: string): Observable<void> {
    return this.http.get<void>(`${environment.apiUrl}/paymentMethods/default/${id}`);
  }
}